<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-class="header">
        <template #header>
          <label v-text="'Personel Güncelle'" class="mr-2 text-white"></label>
          <b-button
            variant="dark"
            class="mr-2"
            to="/personel"
            v-if="hasRole(['federation'])"
            >Personeller</b-button
          >
          <b-button ref="submitButton" variant="primary" type="submit"
            >Gönder</b-button
          >
        </template>
        <b-row
          class="buttonBar"
          style="pointer-events: none"
          align-h="end"
          v-if="navbarVisibility"
        >
          <b-col md="auto" style="pointer-events: auto">
            <b-button
              class="pageButton"
              type="button"
              :variant="currentTab === 0 ? 'light' : 'secondary'"
              :active="currentTab === 0"
              @click="currentTab = 0"
            >
              Nüfus Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              v-if="hasRole(['federation'])"
              :variant="currentTab === 1 ? 'light' : 'secondary'"
              :active="currentTab === 1"
              @click="currentTab = 1"
            >
              Branş Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 2 ? 'light' : 'secondary'"
              :active="currentTab === 2"
              @click="currentTab = 2"
            >
              İletişim Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 3 ? 'light' : 'secondary'"
              :active="currentTab === 3"
              @click="currentTab = 3"
            >
              Öğrenim Bilgileri
            </b-button>
            <b-button
              class="pageButton ml-2"
              type="button"
              :variant="currentTab === 4 ? 'light' : 'secondary'"
              :active="currentTab === 4"
              @click="currentTab = 4"
            >
              Sistem Bilgileri
            </b-button>
          </b-col>
        </b-row>
        <div class="buttonBarMobile" v-else>
          <b-button v-b-toggle.collapse-1 variant="dark">
            <b-icon icon="list"></b-icon
          ></b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-col>
              <b-row>
                <b-button
                  class="pageButtonMobile ml-2 mt-2"
                  type="button"
                  :variant="currentTab === 0 ? 'light' : 'secondary'"
                  :active="currentTab === 0"
                  @click="currentTab = 0"
                >
                  Nüfus Bilgileri
                </b-button>
              </b-row>
              <b-row>
                <b-button
                  class="pageButtonMobile ml-2 mt-2"
                  type="button"
                  v-if="hasRole(['federation'])"
                  :variant="currentTab === 1 ? 'light' : 'secondary'"
                  :active="currentTab === 1"
                  @click="currentTab = 1"
                >
                  Branş Bilgileri
                </b-button>
              </b-row>
              <b-row>
                <b-button
                  class="pageButtonMobile ml-2 mt-2"
                  type="button"
                  :variant="currentTab === 2 ? 'light' : 'secondary'"
                  :active="currentTab === 2"
                  @click="currentTab = 2"
                >
                  İletişim Bilgileri
                </b-button>
              </b-row>
              <b-row>
                <b-button
                  class="pageButtonMobile ml-2 mt-2"
                  type="button"
                  :variant="currentTab === 3 ? 'light' : 'secondary'"
                  :active="currentTab === 3"
                  @click="currentTab = 3"
                >
                  Öğrenim Bilgileri
                </b-button>
              </b-row>
              <b-row>
                <b-button
                  class="pageButtonMobile ml-2 mt-2"
                  type="button"
                  :variant="currentTab === 4 ? 'light' : 'secondary'"
                  :active="currentTab === 4"
                  @click="currentTab = 4"
                >
                  Sistem Bilgileri
                </b-button>
              </b-row>
            </b-col>
          </b-collapse>
        </div>

        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <div v-if="currentTab === 0" class="mt-4">
          <PersonalForm :form="form" :selectedPicture="selectedPicture" />
        </div>
        <div
          v-else-if="currentTab === 1 && hasRole(['federation'])"
          class="mt-4"
        >
          <BranchForm :form="form" />
        </div>
        <div v-else-if="currentTab === 2" class="mt-4">
          <ContactForm :form="form" />
        </div>
        <div v-else-if="currentTab === 3" class="mt-4">
          <EducationForm :form="form" />
        </div>
        <div v-else-if="currentTab === 4" class="mt-4">
          <SystemForm :form="form" />
        </div>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import BranchForm from "@/components/forms/personel/BranchForm.vue";
import ContactForm from "@/components/forms/personel/ContactForm.vue";
import EducationForm from "@/components/forms/personel/EducationForm.vue";
import PersonalForm from "@/components/forms/personel/PersonalForm.vue";
import SystemForm from "@/components/forms/personel/SystemForm.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BranchForm,
    ContactForm,
    EducationForm,
    PersonalForm,
    SystemForm,
  },
  computed: {
    ...mapGetters(["getUser", "getPersonelAddresItem"]),
    form: {
      get() {
        return this.getPersonelAddresItem;
      },
    },
  },
  data() {
    return {
      currentTab: 0,
      selectedPicture: {
        file: null,
        fileName: "Dosya seçin veya buraya sürükleyin...",
      },
      navbarVisibility: true,
    };
  },
  created() {
    if (this.$store.getters.checkToken.isFederation) {
      this.$store.dispatch("initpersonelAddresItem", this.$route.params.id);
    } else {
      this.$store.dispatch("initpersonelAddresItem", this.getUser.id);
    }
    if (this.isMobile()) {
      this.navbarVisibility = false;
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.selectedPicture.file) {
        this.form.changepictureAddress = this.form.pictureAddress;
        this.form.pictureAddress = this.selectedPicture.file;
      }
      this.$store.dispatch("updatePersonel", this.form).then(() => {
        if (this.hasRole(["federation"])) {
          this.$router.push("/personel");
        }
      });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkFormValidity() {
      if (
        ![
          this.form.ssn,
          this.form.surname,
          this.form.name,
          this.form.gender,
        ].every((e) => e)
      ) {
        this.currentTab = 0;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      } else if (
        ![this.form.contact.cityWhereLocated].every(
          (e) => e
        )
      ) {
        this.currentTab = 2;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      } else if (![this.form.system.password].every((e) => e)) {
        this.currentTab = 4;
        setTimeout(() => this.$refs.submitButton.click(), 0);
        return false;
      }
      return true;
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.selection-field {
  max-width: 16rem;
  min-width: 16rem;
}
.align-right {
  margin-right: 1rem;
}
.tab {
  color: white;
  background: gray;
}
.pageButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.buttonBar {
  margin-top: -3.65rem;
}
.spacing {
  margin-top: 1.5rem;
}
.dark {
  color: black;
}
.buttonBarMobile {
  text-align: end;
  margin-top: -4rem;
}
.pageButtonMobile{
  width: 12rem;
}
</style>